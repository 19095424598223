import Image from "next/legacy/image";
import Link from 'next/link';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import Button from 'components/Button';
import WaveImage from 'public/images/wave-image.png';

export default function FourOhFour() {
  useEffect(() => {
    Sentry.captureMessage('Page Not Found', {
      level: 'error',
    });
  }, [])

  return (
    (<div className='relative h-screen bg-primary-lightest/10'>
      <div className='absolute z-10 m-auto right-1/2 bottom-1/2'>
        <h1 className='text-title1 font-bold text-ink-lightest'>404</h1>
        <h2 className='text-title1 font-bold'>Page not found</h2>
        <div className='mt-20'>
          <Link href="/dashboard" legacyBehavior>
            <Button text='Back to Dashboard' />
          </Link>
        </div>
      </div>
      <div className='absolute w-full bottom-0 left-0 h-200'>
        <Image src={WaveImage} placeholder="blur" alt="wave" layout="fill" objectFit="cover" />
      </div>
    </div>)
  );
}
